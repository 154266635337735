/* global chrome */

import React from "react";
import "./Invitations.css";
import { Alert, Button, CircularProgress, Typography } from "@mui/material";
import useBrowserExtensionStatus, {
  sendInvitationsToExtension,
} from "../Extension/Extension";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";

import { useNavigate, useSearchParams } from "react-router-dom";
import { Invitation } from "./Invitation";

import { useTranslation } from "react-i18next";
import { ConnectSection } from "../Installation/sections/ConnectSection";
import {
  OS,
  appStoreLink,
  checkOS,
  playStoreLink,
  supportedIOSes,
  supportedMobileOSes,
} from "../System/System";
import { setupDeferredDeepLinkForLatestInvitation } from "../Deeplinks/Deeplinks";
import TopBar from "../General/TopBar";

interface InvitationProps {
  navigator: any;
  browser: any;
}

export const getInvitations = () => {
  let invitations: Invitation[] = [];
  const invitationsRaw = window.localStorage.getItem("invitations");
  if (invitationsRaw) {
    try {
      const invitationsStored: Invitation[] = JSON.parse(invitationsRaw);
      invitations = invitations.concat(invitationsStored);
    } catch {
      console.log("json from storage not readable");
    }
  }
  return invitations;
};

const Invitations: React.FC<InvitationProps> = (props: InvitationProps) => {
  const { t } = useTranslation();
  const [silent, setSilent] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>("");
  const [messaging, setMessaging] = React.useState<boolean>(false);
  const [messagingError, setMessagingError] = React.useState<boolean>(false);
  const [messagingSuccess, setMessagingSuccess] =
    React.useState<boolean>(false);
  const [invitations, setInvitations] = React.useState<
    Invitation[] | undefined
  >(undefined);
  const [os, setOS] = React.useState<undefined | OS>(undefined);

  const saveInvitations = React.useCallback((invitations: Invitation[]) => {
    // TODO remove duplicates (keep oldest)
    window.localStorage.setItem("invitations", JSON.stringify(invitations));
  }, []);

  const [searchParams, setSearchParams] = useSearchParams();

  const extension = useBrowserExtensionStatus(props.browser, props.navigator);

  const navigate = useNavigate();

  const sendInvitations = React.useCallback(
    (
      browser: typeof chrome,
      invitations: Invitation[],
      openExtension: boolean
    ) => {
      try {
        setMessaging(true);
        setMessagingError(false);
        setMessagingSuccess(false);
        if (invitations && invitations.length > 0) {
          console.log("Sending invitations to extension");
          sendInvitationsToExtension(
            browser,
            navigator,
            invitations,
            !openExtension,
            (reply: any) => {
              if (reply && reply.ok) {
                setMessagingSuccess(true);
              } else {
                setMessagingError(true);
                saveInvitations(invitations);
              }
              setMessaging(false);
            }
          );
        } else {
          setMessaging(false);
        }
      } catch (e: any) {
        setMessaging(false);
        setMessagingError(true);
        setError(t("invitations.errorMessaging"));
        console.log(e);
        console.debug(e);
      }
    },
    [t, saveInvitations]
  );

  //check supported
  React.useEffect(() => {
    //check if on a supported system for the token-flow (desktop OS & supported browser)
    const os = checkOS(props.navigator);
    setOS(os);
  }, [props.navigator]);

  React.useEffect(() => {
    let invitations: Invitation[] = [];

    // check if there is a token available in the querystring
    const invitationToken = searchParams.get("token");
    setSilent(searchParams.has("silent"));
    setSearchParams("");
    if (invitationToken) {
      invitations.push({
        token: invitationToken,
        received: Date.now(),
      });
    }

    // get invitations from localstorage, add them
    invitations = invitations.concat(getInvitations());
    setInvitations(invitations);
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (invitations) {
      // if extension installed
      if (extension.installed !== undefined) {
        if (extension.installed) {
          // remove all invitations from storage
          saveInvitations([]);

          // send invitations in extension, open the extension if not requested silently
          sendInvitations(props.browser, invitations, !silent);
        } else {
          // Not installed
          saveInvitations(invitations);
        }
      }
    }

    // eslint-disable-next-line
  }, [extension.checking, extension.installed, saveInvitations]);

  return (
    <div className="root">
      <TopBar transaction={null} signIn={false} />
      <div className="content-container">
        <div className="content-token">
          {extension.installed !== undefined && !extension.installed ? (
            <ConnectSection
              goToInstallation={async () => {
                if (supportedIOSes.includes(os as OS)) {
                  await setupDeferredDeepLinkForLatestInvitation(); //can only be done in response to a user interation
                  console.debug("testing:redirectedToIOSAppStore");
                  window.open(appStoreLink, "_self");
                } else if (os === "Android") {
                  await setupDeferredDeepLinkForLatestInvitation(); //can only be done in response to a user interation
                  window.open(playStoreLink, "_self");
                } else {
                  navigate("/installation?invited");
                }
              }}
              organization={undefined}
              organizationIconUrl={undefined}
              isSkippable={false}
              mobile={supportedMobileOSes.includes(os as OS)}
              invited={true}
            />
          ) : messaging ? (
            <div className="messaging">
              <div>
                <CircularProgress className="indicator" />
              </div>
              <div>
                <Typography>{t("Invitations.messaging")}</Typography>
              </div>
            </div>
          ) : messagingSuccess ? (
            <div className="success">
              {t("Invitations.success")}
              <CheckCircleIcon className="SuccessIcon" />
              <Typography>{t("Invitations.continue")}</Typography>
            </div>
          ) : messagingError ? (
            <div className="error">
              <ErrorIcon className="ErrorIcon" />
              <Typography>{t("Invitations.error")}</Typography>
              <div className="openInvitationsContainer">
                <Button
                  variant="outlined"
                  className="black-button"
                  onClick={() => {
                    invitations &&
                      sendInvitations(props.browser, invitations, true);
                  }}
                >
                  <Typography>{t("Invitations.tryAgain")}</Typography>
                </Button>
              </div>
              {error && (
                <div className="alertContainer">
                  <Alert className="alert" severity="error" icon={false}>
                    {error}{" "}
                  </Alert>
                </div>
              )}
            </div>
          ) : (
            <div className="loading">
              <div>
                <CircularProgress className="indicator" />
              </div>
              <div>
                <Typography>{t("Invitations.loading")}</Typography>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Invitations;
